
import Vue from 'vue'
import moment from 'moment'
import { environment } from '@/environment'

export default Vue.extend({
  name: 'SearchBar',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    enableRequiredRule: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActive: false,
      fromDate: moment().subtract(1, 'weeks').toDate().toString(),
      // fromDate: moment('2023-11-01').toString(),
      toDate: new Date().toString(),
      // toDate: moment('2023-11-21').toString(),
      number: '',
      // number: '6038AM76'
      maxDate: Date.now(),
      timezone: environment.tz,

      checkMax: (str: string) => str.length < 10 || 'Длина должна быть не больше 8 символов',
      checkLang: (str: string) => !/[А-Яа-я]/.test(str) || 'Только латинские буквы и символы',
      requiredRule: (value: string) => !!value || 'Поле не может быть пустым.',
    }
  },
  methods: {
    clear() {
      this.fromDate = ''
      this.toDate = ''
      this.number = ''
      this.$emit('clear')
    },
    search() {
      moment.suppressDeprecationWarnings = true // чтобы убрать warning в консоли

      const from = moment(this.fromDate).toDate()
      const to = moment(this.toDate).toDate()

      this.$emit('search', {
        from: from.getTime() ? from : undefined,
        to: to.getTime() ? to : undefined,
        number: this.number,
      })
    },
    isSearchDisabled() {
      return !this.fromDate || !this.toDate || (!this.number && this.enableRequiredRule)
    },
    isClearInputVisible() {
      return this.fromDate || this.toDate || this.number
    },
  },
})
