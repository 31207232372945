export class ManagedTime {
  private _paused: boolean = false
  private _freezedTime: number = 0

  public isStopped: boolean = false

  private _getTime: () => number = () => Date.now()

  constructor() {
    if ('performance' in window && 'now' in window.performance) {
      this._getTime = () => window.performance.now()
    }
  }

  public now() {
    if (this._paused) return this._freezedTime
    return this._getTime() - this._freezedTime
  }

  public pause() {
    this._freezedTime = this._getTime()
    this._paused = true
  }

  public resume() {
    this._paused = false
    this._freezedTime = this._getTime() - this._freezedTime
  }

  public reset() {
    this.isStopped = false
    this._freezedTime = 0
    this._paused = false
  }
}

export const time = new ManagedTime()
