export const environment = {
  apiBase: '/gateway/finder',
  authBase: '/',
  photoBaseUrl: 'http://10.25.2.51/',
  photoOrigin: '/gateway/finder-photo/',
  pdfUrl: 'http://192.168.166.15:9000',
  osm: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
  osrm: 'http://10.25.2.51:5000',
  center: [53.1966, 50.1666],
  zoom: 14,
  lang: 'ru',
  tz: 'Europe/Samara',
}
