import { AxiosInstance } from 'axios'

export class TrafficAPI {
  _http: AxiosInstance
  constructor(_http: AxiosInstance) {
    this._http = _http
  }

  public getByNumber(data: TrafficRequest) {
    return this._http
      .get<any>('/passages/filter/license-plate', {
        params: data,
      })
      .then((response) => response.data)
  }
}
