import Axios from 'axios'
import { environment } from '../environment'

import { TrafficAPI } from './traffic.api'
import { OSRMAPI } from './osrm.api'
import { CDDAPI } from './cdd.api'
import { DocumentsAPI } from './pdf.api'

export class FinderAPI {
  constructor() {
    const axiosInstance = Axios.create({
      baseURL: environment.apiBase,
    })
    this.cdd = new CDDAPI(axiosInstance)
    this.traffic = new TrafficAPI(axiosInstance)
    this.documents = new DocumentsAPI(axiosInstance)
    this.osrm = new OSRMAPI(Axios.create({ baseURL: environment.osrm }))
  }

  traffic: TrafficAPI
  osrm: OSRMAPI
  documents: DocumentsAPI
  cdd: CDDAPI
}
