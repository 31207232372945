/* eslint-disable camelcase */
import moment from 'moment'

export function decodePolyline(str: string, precision?: number) {
  let index = 0
  let lat = 0
  let lng = 0
  // eslint-disable-next-line
  let coordinates = new Array<Array<number>>()
  let shift = 0
  let result = 0
  let byte = null
  let latitude_change
  let longitude_change
  // eslint-disable-next-line
  let factor = Math.pow(10, precision || 5)

  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {
    // Reset shift, result, and byte
    byte = null
    shift = 0
    result = 0

    do {
      byte = str.charCodeAt(index++) - 63
      result |= (byte & 0x1f) << shift
      shift += 5
    } while (byte >= 0x20)

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1

    shift = result = 0

    do {
      byte = str.charCodeAt(index++) - 63
      result |= (byte & 0x1f) << shift
      shift += 5
    } while (byte >= 0x20)

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1

    lat += latitude_change
    lng += longitude_change

    coordinates.push([lat / factor, lng / factor])
  }

  return coordinates
}

export function splitLine(start: Array<number>, end: Array<number>, segments: number) {
  const [startLat, startLng] = start
  const [endLat, endLng] = end

  const x_delta = (endLat - startLat) / segments
  const y_delta = (endLng - startLng) / segments

  const points = []

  for (let i = 1; i < segments; i++) {
    points.push([startLat + i * x_delta, startLng + i * y_delta])
  }

  return [start, ...points, end]
}

export function groupedItems(response: Array<TrafficResponseItem>) {
  return response.reduce((map, p) => {
    const timestamp = moment(p.datetime).format('YYYY-MM-DD')
    if (!map[timestamp]) map[timestamp] = []
    map[timestamp].push(p)

    return map
  }, {} as any)
}

export function groupedMapActionItems(groupedItem: any, lp: string) {
  return Object.keys(groupedItem)
    .sort((a, b) => moment(a).toDate().getTime() - moment(b).toDate().getTime())
    .map((key) => ({
      timestamp: moment(key).format('DD.MM.YYYY'),
      traffic_list: groupedItem[key]
        .map((item: any) => ({
          datetime: item.datetime,
          image: item.image,
          location: item.location,
          license_plate: lp,
          model: item.vehicle.model.name,
          country: item.vehicle.country.description,
          kind: item.vehicle.kind.description,
          uuid: item.uuid,
          color: item.vehicle.color.description,
        }))
        .sort((a: any, b: any) => a.timestamp - b.timestamp),
    }))
}

export function momentFilter(date: Date | string | number): string {
  const utcTemp = moment.utc(date).toDate()
  return moment(utcTemp).local().format('DD.MM.YYYY HH:mm:ss')
}
