
import Vue from 'vue'
import { latLng, layerGroup, polyline, marker, divIcon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LTooltip, LControlZoom, LIcon } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { environment } from '../environment'
import '@/plugins/polyline-animation'
import MarkerCircle from '@/components/common/MarkerCircle.vue'

import { time } from '../plugins/managed-time'
import moment from 'moment'

export default Vue.extend({
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControlZoom,
    LIcon,
    MarkerCircle,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  props: {
    cddList: {
      type: Array as () => CDDSource[],
      default: () => [],
    },
    routeList: {
      type: Array,
      default: () => [],
    },
    drawRoutes: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    zoom: environment.zoom,
    center: latLng(...environment.center),
    url: environment.osm,
    currentZoom: environment.zoom,
    currentCenter: latLng(...environment.center),
    mapOptions: {
      zoomSnap: 0.5,
      zoomControl: false,
    },
    line: [],
    drawnItems: null as any,
    map: null as any,
  }),
  computed: {
    visibleSources(): CDDSource[] {
      return this.cddList.filter((k: CDDSource) => k.location)
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs.myMap) return
      this.map = (this.$refs.myMap as any).mapObject
    })

    if (this.$props.drawRoutes) {
      this.$watch('routeList', () => {
        if (!this.map) return

        if (this.drawnItems) {
          this.drawnItems.snakeStop()
          this.map.removeLayer(this.drawnItems)
          this.drawnItems = null
        }

        if (!this.routeList.length) return

        this.drawnItems = layerGroup(
          this.routeList.map((rl: any) => {
            if (Array.isArray(rl)) {
              return polyline(rl, {
                opacity: 1,
                snakingSpeed: 300,
              })
            } else {
              return marker(rl.position, {
                icon: divIcon({
                  className: 'image-from-camera',
                  html: `<img src="${this.getImage(rl.image)}"/>`,
                }),
              }).on('click', (e: any) => this.$emit('currentItem', rl))
            }
          }),
          { snakingPause: 2000 },
        )

        this.drawnItems = this.drawnItems.addTo(this.map)

        if (!time.isStopped) {
          this.drawnItems.on('snakeend', () => {
            this.$emit('routecomplete')
          })
          this.drawnItems.snakeIn()
        }
      })
    }
  },
  methods: {
    getImage(src: string) {
      return `${environment.photoOrigin}/${src}`
    },
    zoomUpdated(zoom: any) {
      this.zoom = zoom
    },
    getTimeTooltip(timeStamp: number) {
      return moment.utc(timeStamp).local().format('YYYY-MM-DD HH:mm:ss')
    },
    centerUpdated(center: any) {
      this.center = center
    },
    getCoordinates(kdd: CDDSource) {
      return latLng(kdd.location!.latitude, kdd.location!.longitude)
    },
  },
})
